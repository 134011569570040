export const friendlyDate = a => {
	const date = new Date(a);

	const months = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	];

	const shortMonths = [
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	];

	const day = date.getDate();
	const month = months[date.getMonth()];
	const shortMonth = shortMonths[date.getMonth()];
	const year = date.getFullYear();

	return { day, month, shortMonth, year };
};

export const getTimeToRead = text =>
	`${Math.ceil(
		text.replace(/<\/?[^>]+(>|$)/g, '').split(' ').length / 200
	)} minutos para ler`;
