import styled from 'styled-components';
import { Link as GLink } from 'gatsby';

import { colors, media, spacing, toRem } from '@styles/Theme';
import { Wrapper as LWrapper } from '@components/Layout/styles';

import CCTA from '@components/CTAS/Appointment';

export const Wrapper = styled(LWrapper)`
	@media (${media.tablet}) {
		padding-left: ${spacing(8)};
	}

	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-left: ${spacing(15)};
	}
`;

export const MainContent = styled.div`
	h1 {
		margin-bottom: ${spacing(4)};
		line-height: 1.2;
	}

	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Content = styled.div`
	.fr-video {
		position: relative;
		display: block;
		padding-top: 56.25%;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}

	img {
		display: block;
		width: 100% !important;
	}

	p {
		margin-bottom: ${spacing(2)};
	}

	table {
		td {
			padding: ${spacing(2)};
			font-size: ${toRem(12)};

			&:not(:first-child) {
				border-left: ${toRem(1)} solid ${colors.lightGrey};
			}
		}
	}
`;

export const CTA = styled(CCTA)`
	margin: ${spacing(5, 'auto')};
`;

export const Link = styled(GLink)`
	position: relative;
	display: flex;
	align-items: center;
	height: ${spacing(9)};
	padding: ${spacing(0, 6, 0, 3)};
	overflow: hidden;
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: ${toRem(18)};
	text-decoration: none;
	background-color: #fff;
	border-radius: ${spacing(1)};
	cursor: pointer;
	transition: all 0.2s linear;
	user-select: none;

	&:not(:last-child) {
		margin-bottom: ${spacing(1)};

		@media (${media.tablet}) {
			margin-bottom: ${spacing(3)};
		}
	}

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: ${colors.gradient(150)};
		opacity: 0;
		transition: opacity 0.1s linear;
		content: '';
	}

	svg {
		position: absolute;
		top: ${spacing(3)};
		right: ${spacing(2)};
		flex-shrink: 0;
		color: ${colors.lightGrey};
	}

	span {
		position: relative;
	}

	&:hover {
		color: #fff;

		&::before {
			opacity: 1;
		}

		svg {
			color: #fff;
		}
	}
`;

export const Related = styled.div`
	margin-bottom: ${spacing(4)};

	> h3 {
		padding-bottom: ${spacing(3)};
		text-align: center;
		border-bottom: ${toRem(1)} solid ${colors.lightGrey};
	}
`;

export const RelatedItem = styled(GLink)`
	display: block;
	margin-bottom: ${spacing(4)};

	img {
		display: block;
		width: 100%;

		@media (${media.mobileOnly}) {
			margin-bottom: ${spacing(1)};
		}
	}

	h3 {
		margin: 0;
	}

	span {
		position: relative;
		padding-left: ${spacing(4)};
		color: ${colors.lightGrey};
		font-size: ${toRem(14)};

		&:before {
			position: absolute;
			top: calc(50% - ${spacing(1)});
			left: 0;
			width: ${spacing(3)};
			height: ${spacing(2)};
			background-color: ${colors.grey};
			content: '';
		}
	}

	@media (${media.tablet}) {
		display: flex;

		img {
			max-width: ${toRem(160)};
			margin-right: ${spacing(2)};
		}
	}
`;

export const SupHeader = styled.div`
	margin-bottom: ${spacing(2)};
	padding-bottom: ${spacing(2)};
	border-bottom: ${toRem(1)} dashed ${colors.lightGrey};

	@media (${media.tablet}) {
		display: flex;
		justify-content: space-between;
	}
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;

	@media (${media.mobileOnly}) {
		margin-bottom: ${spacing(2)};
	}
`;

export const ShareContainer = styled.div`
	@media (${media.tablet}) {
		text-align: right;
	}
`;

export const ShareItems = styled.div`
	/* stylelint-disable-next-line */
	svg {
		width: ${spacing(4)};
		margin-left: ${spacing(1)};
	}

	@media (${media.desktop}) {
		svg {
			width: ${spacing(3)};
		}
	}
`;

export const Header = styled.header``;

export const Category = styled.div`
	padding-top: ${spacing(2)};
	border-top: ${toRem(1)} solid ${colors.lightGrey};

	span {
		position: relative;
		padding-left: ${spacing(4)};
		color: ${colors.lightGrey};
		font-size: ${toRem(14)};

		&:before {
			position: absolute;
			top: calc(50% - ${spacing(1)});
			left: 0;
			width: ${spacing(3)};
			height: ${spacing(2)};
			background-color: ${colors.grey};
			content: '';
		}
	}
`;
