import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from 'react-share';

import { H1, H3, H5 } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import Sidebar from '@components/Sidebars/Main';
import {
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon,
} from '@components/Icons';

import { getTimeToRead, friendlyDate } from '@utils/postData';

import * as S from './_styles';

const url = 'http://infusoes-hml.s3-website-us-west-2.amazonaws.com/';

export const pageQuery = graphql`
	query PostBySlug($slug: String!, $category: String!) {
		post: allCosmicjsPosts(filter: { slug: { eq: $slug } }) {
			nodes {
				created_at
				title
				slug
				metadata {
					thumb {
						imgix_url
					}
					category {
						title
					}
					body
					metas {
						description
						title
					}
					summary
				}
			}
		}

		related: allCosmicjsPosts(
			filter: {
				metadata: { category: { slug: { eq: $category } } }
				slug: { ne: $slug }
			}
			sort: { fields: created_at, order: DESC }
		) {
			nodes {
				created_at
				title
				slug
				metadata {
					category {
						title
					}
					thumb {
						imgix_url
					}
				}
			}
		}
	}
`;

const PostTemplate = ({ data }) => {
	const {
		title,
		created_at,
		metadata: {
			category: { title: categoryTitle },
			body,
			thumb: { imgix_url: thumb },
			metas,
			summary,
		},
	} = data.post.nodes[0];

	const related = data.related.nodes;

	const timeToRead = useMemo(() => getTimeToRead(body), [body]);

	const createdAt = useMemo(() => {
		const { day, month, year } = friendlyDate(created_at);

		return `Publicado em ${day} de ${month} de ${year}`;
	}, [created_at]);

	const slugPost = data.post.nodes[0].slug;

	return (
		<>
			<SEO
				title={metas?.title || title}
				description={
					metas?.description ||
					`${summary.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 156)}...`
				}
				cover={thumb}
				metaType="article"
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[
						{ title: 'Blog', slug: 'blog' },
						{ title, slug: `blog/${slugPost}` },
					]}
				/>
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<S.SupHeader>
							<S.Infos>
								<span>{createdAt}</span>
								<b>{timeToRead}</b>
							</S.Infos>

							<S.ShareContainer>
								<b>Compartilhar:</b>
								<S.ShareItems>
									<FacebookShareButton
										url={url}
										quote={title}
										hashtag="#fleury"
									>
										<FacebookIcon />
									</FacebookShareButton>

									<LinkedinShareButton url={url}>
										<LinkedinIcon />
									</LinkedinShareButton>

									<TwitterShareButton url={url} title={title}>
										<TwitterIcon />
									</TwitterShareButton>

									<WhatsappShareButton url={url} title={title} separator=":: ">
										<WhatsappIcon />
									</WhatsappShareButton>
								</S.ShareItems>
							</S.ShareContainer>
						</S.SupHeader>

						<S.Header>
							<H1>{title}</H1>
						</S.Header>

						<S.Content dangerouslySetInnerHTML={{ __html: body }} />

						<S.Category>
							<span>{categoryTitle}</span>
						</S.Category>

						<S.CTA />
						{!!related.length && (
							<S.Related>
								<H3>Você também pode gostar de:</H3>
								{related.map(
									({
										slug,
										title: relatedTitle,
										metadata: {
											category: { title: relatedCategory },
											thumb: { imgix_url: thumbnail },
										},
									}) => (
										<S.RelatedItem key={slug} to={`/blog/${slug}`}>
											<img src={thumbnail} alt="" />
											<div>
												<H5>{relatedTitle}</H5>
												<span>{relatedCategory}</span>
											</div>
										</S.RelatedItem>
									)
								)}
							</S.Related>
						)}
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

PostTemplate.propTypes = {
	data: PropTypes.shape({
		post: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object),
		}),
		related: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object),
		}),
	}).isRequired,
};

export default PostTemplate;
